import React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { Link, graphql } from "gatsby";
import Img from 'gatsby-image';
import Layout from "../components/layout";

const About = ({ data: { about } }) => (
  <Layout>
    <div className="subpageWrapper">
      <HelmetDatoCms seo={about.seoMetaTags} />

      <div className="morph-wrap">
        <svg width="auto" height="700" viewBox="0 0 642 700" fill="none" className="morph" id="blob-bg-subpage" xmlns="http://www.w3.org/2000/svg">
          <path d="M438.336 -208.256C292.672 -356.784 137.336 -244.256 108.336 -188.256C78.3359 -158.256 75.3359 -42.7556 52.8359 28.7444C32.7422 92.5978 -10.6641 90.2444 -36.6641 68.2444C-62.664 46.2444 -141.664 23.0777 -179.664 15.7444C-202.164 5.74435 -213.164 -15.7557 -210.664 -36.2558C-208.214 -56.3455 -228.331 -110.256 -239.664 -134.256C-289.719 -240.256 -453.2 -342.103 -542.164 -195.756C-657.664 -5.75574 -462.664 44.7444 -397.664 59.7444C-335.086 74.1854 -403.515 170.608 -411.664 190.244C-453.164 290.244 -399.164 519.744 -187.164 519.744C-133.524 519.744 -95.6638 545.744 -71.664 579.744C-55.7817 602.244 -51.9068 625.344 -11.6636 651.744C191.836 785.244 336.836 608.244 348.336 503.744V503.744C348.336 475.743 348.336 403.744 472.335 367.744C514.59 355.477 676.898 207.73 634.335 81.7444C571.836 -103.256 616.336 -26.7557 438.336 -208.256Z" fill="url(#paint0_linear)" />
          <defs>
            <linearGradient id="paint0_linear" x1="-18.5" y1="-77.5" x2="380.336" y2="591.744" gradientUnits="userSpaceOnUse">
              <stop stopColor="#D29411" />
              <stop offset="0.642482" stopColor="#FDB600" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="columns is-centered">
        <div className="column is-12-mobile is-10-tablet is-offset-2-tablet is-12-desktop">
          <div className="logo">
            <Link to="/">
              <img alt="Zena B's Raw Honey" title="Zena B's Raw Honey" src="https://www.datocms-assets.com/7775/1539919614-zena-b-raw-honey-logo.svg" />
            </Link>
          </div>
        </div>
      </div>

      <div className="columns is-centered">
        <div className="column is-10">
          <h1 className="script">{about.title}</h1>
          <p>{about.subtitle}</p>
          <div className="columns">
            <div className="column is-half">
              <Img sizes={about.photo.sizes} />
            </div>

            <div className="column is-half">
              <div
                dangerouslySetInnerHTML={{
                  __html: about.bioNode.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default About

export const query = graphql`
  query AboutQuery {
    about: datoCmsAboutPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
      photo {
        sizes(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      bioNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
